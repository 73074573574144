// Abstract specific font names away
$optima-conv-optima: 'Conv_OptimaEL-Text';

$optimalight: $optimaregular;

// Font-face Optima EL Text.
@font-face {
  font-family: '#{$optima-conv-optima}';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
}
