iframe.bisMobileIframe {
  height: 500px;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $optimalight;
}

.font--optima-light {
  font-family: $optimalight;
}

.elb-privacy-policy {
  h2 {
    font-size: 38px;
  }
}

//Product Foundation VTO
.product-foundation-vto {
  &__overlay-check {
    #vto-disclaimer-checkbox ~ label::before {
      border: 1px solid $color-black;
    }
  }
}

.page-wrapper {
  .utility-nav {
    &__signin-text {
      @media #{$device-small-only} {
        font-size: 10px;
      }
    }
  }
}

.page-branding {
  &__logo-svg {
    @media #{$device-small-only} {
      width: 170px;
    }
  }
}
