.enhanced-cart-page {
  margin-top: 0;
  @media #{$medium-up} {
    margin-top: 0;
  }
  .viewcart-buttons {
    border-bottom: 1px solid $color-light-gray;
    .payments-icon {
      .diners-card {
        background: url('/media/images/checkout/card_diners.png') no-repeat;
      }
      .magna-card {
        background: url('/media/images/checkout/card_magna.png') no-repeat;
      }
      .cmr-card {
        background: url('/media/images/checkout/card_cmr.png') no-repeat;
      }
      .webpay {
        background: url('/media/images/checkout/webpay.jpg') no-repeat;
      }
      .diners-card,
      .magna-card,
      .cmr-card,
      .webpay {
        background-position: center;
        background-size: 100%;
        height: 16px;
        margin: 0 7px;
        max-width: 32px;
        width: 24px;
        @media #{$large-only} {
          margin: 0 4px;
        }
      }
      .paypal {
        display: none;
      }
    }
  }
  .checkout {
    @include clearfix;
    position: static;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    max-width: $checkout-width;
    font-size: $checkout-font-size--base;
    letter-spacing: initial;
    @media #{$large-up} {
      position: relative;
      padding-bottom: 150px;
    }
    .checkout__content {
      .cart-header {
        display: none;
      }
      .signin-to-see-cart {
        font-size: 15px;
      }
      .cart-item {
        width: 100%;
        &__remove-form {
          position: absolute;
          margin: 0;
          .remove_link {
            text-indent: -9999px;
            background-size: contain;
            display: block;
          }
        }
        &__thumb {
          padding: 0;
          float: left;
        }
        &__total {
          position: static;
          float: right;
        }
        &__desc {
          font-family: $optimaregular;
          font-size: 12px;
          letter-spacing: initial;
        }
      }
      .viewcart-product__content {
        .cart-items {
          .cart-item {
            &__price {
              width: auto;
              .strike_price.esteeblue {
                color: $ff-color-estee-blue;
              }
              .strike_price.price1 {
                margin-#{$rdirection}: 29px;
                width: 166px;
              }
              &-content {
                position: relative;
                margin-bottom: 20px;
              }
              &.column {
                @media #{$large-up} {
                  display: flex;
                  flex-wrap: wrap;
                }
              }
            }
            &__price.percent-discount {
              .unit_price {
                @media #{$medium-up} {
                  float: right;
                }
              }
              .strike_price.price1 {
                margin-right: 77px;
              }
            }
            &__total {
              position: static;
              float: right;
              &.sale-price {
                position: absolute;
                top: 24px;
                @media #{$large-up} {
                  position: static;
                }
              }
              &.sale-price.percent-discount {
                position: absolute;
                top: 47px;
                @media #{$large-up} {
                  position: absolute;
                  top: 23px;
                }
              }
            }
            &__total.percent-discount {
              position: absolute;
              top: 25px;
              @media #{$large-up} {
                position: static;
              }
            }
            &__price-discount {
              display: flex;
              flex-wrap: wrap;
              width: 100%;
              .strike_price {
                width: 178px;
              }
            }
          }
        }
      }
    }
    .panel.order-summary {
      margin-bottom: 0;
      .order-summary__content {
        @include clearfix;
        font-size: 14px;
        letter-spacing: 0.1em;
        .order-summary__shipping {
          @media #{$medium-up} {
            margin-bottom: 16px;
          }
        }
        .order-summary__total-label {
          background-color: $color-lighter-gray;
          margin: 0;
        }
        .total-label__wrapper {
          padding: 0;
        }
        .order-summary__total-value {
          background-color: $color-lighter-gray;
          line-height: 18px;
        }
        .total-value__wrapper {
          padding: 0;
          border-top: 0;
        }
      }
      .discount.label,
      .discount.value {
        margin-bottom: $checkout-spacing--medium;
      }
      .discount.label {
        padding-left: $checkout-spacing--extra-large;
        width: 70%;
      }
      .discount.value {
        padding-right: $checkout-spacing--extra-large;
        width: 30%;
        font-family: $akzidenz;
        font-size: $checkout-font-size--base;
        font-weight: bold;
        letter-spacing: 0.05em;
        line-height: $checkout-spacing--large;
      }
      .label {
        float: left;
        text-align: left;
        text-transform: uppercase;
        line-height: 18px;
        margin: 0 0 6px;
      }
      .value {
        float: right;
        margin: 0;
        text-align: right;
      }
    }
    .form-item {
      select {
        margin-top: $checkout-spacing--extra-small;
        width: 100%;
        height: calc(4em + 2px);
        border: $checkout-line--input;
        border-radius: 0;
        color: $color-navy;
        @include box-shadow(none);
        &:disabled {
          background-color: $color-lighter-gray;
        }
        @media #{$large-up} {
          height: calc(3.5em + 2px);
          border: $checkout-line--input-light-gray;
        }
      }
      &.ship-method {
        margin-bottom: 0;
      }
    }
    .panel--offer-code {
      .offer-code__form {
        form {
          font-size: 12px;
          letter-spacing: initial;
        }
      }
    }
    .column.left {
      padding: 0;
      border: none;
      background-color: transparent;
      font-size: 15px;
      .single-message {
        margin-top: 0;
        margin-bottom: $checkout-spacing--large;
        text-align: center;
        padding: 0 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .messages {
        padding: 0 24px;
        margin: 0 0 $checkout-spacing--large;
        @media #{$large-up} {
          margin: 0;
          padding: 0;
          text-align: center;
          font-family: $optimaregular;
          font-size: 22px;
        }
      }
    }
    #checkout-sidebar {
      .top-viewcart-buttons {
        display: block;
        margin: 0;
      }
      .links-panel-content {
        margin-bottom: 19px;
        @media #{$large-up} {
          margin-bottom: 0;
        }
      }
    }
    .form-container {
      @include clearfix;
    }
    ///
    /// Adaptive Placeholder Adjustments
    ///
    .adpl {
      input[type='text'] {
        @include adaptive-placeholder($checkout-adaptive-placeholder-parameters);
        border: $checkout-line--input;
        & + label {
          @media #{$large-up} {
            line-height: 4.4;
          }
        }
      }
    }
  }
  .pg_wrapper.checkout {
    @media #{$large-up} {
      padding: 20px 0 0;
    }
  }
  .checkout__sidebar#checkout-sidebar {
    .viewcart-buttons.is-mobile {
      .viewcart-buttons-panel {
        border-top: 0;
      }
    }
  }
  .recommended-section {
    display: none;
    @media #{$large-up} {
      display: block;
    }
    .recommended-products-panel__title {
      border-bottom: none;
    }
    .recommended-products__list {
      .sku-brief-editorial {
        &__price {
          margin-bottom: 16px;
        }
        &__image {
          width: 100%;
          max-width: 144px;
          margin: auto;
          height: auto;
        }
      }
    }
  }
  .viewcart-empty {
    .panel--offer-code {
      display: none;
    }
    .messages {
      text-align: center;
      width: 100%;
    }
    .js-cart-items.products {
      border: 0;
      margin: 0;
      padding: 0;
    }
  }
  .guarantee-panel {
    padding: 10px 0 40px;
    @media #{$large-up} {
      padding: 0;
    }
    &__title {
      @include swap_direction(padding, 0 25px 0 16px);
      border: 0;
      color: $glux-color-submenu-text;
      font-family: $optimalight;
      font-size: 18px;
      font-weight: 600;
      height: auto;
      letter-spacing: 0;
      line-height: 1.35;
      margin: 0 0 5px;
      width: auto;
      text-transform: inherit;
      @media #{$medium-up} {
        font-size: 26px;
        letter-spacing: 1px;
        line-height: 1.5;
        padding-#{$ldirection}: 0;
      }
    }
  }
}