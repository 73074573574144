.registration-page {
  .form-item.margin-bottom {
    margin-bottom: 10px;
  }
}
.address-form {
  .address-form__state-container {
    select {
      width: 100%;
    }
  }
  .address-form__ship-type-container {
    display: block;
  }
}
.account-page {
  .mobile-forms-wrapper {
    .payment-form {
      .payment-form__fieldset {
        .headline--section {
          padding-left: 0;
        }
      }
    }
  }
  .registration-page {
    .registration-page__content {
      .main-button-wrapper {
        .button--dark {
          font-size: 11px;
        }
      }
    }
  }
}

#registration {
  .profile-password-update__fieldset {
    .profile-password-update__rules {
      padding: 0;
      li {
        white-space: wrap;
      }
    }
  }
}
