.enhanced-cart-page {
  .checkout {
    .checkout__content {
      .viewcart-product__content {
        .cart-item__subitems {
          font-family: $optimaregular;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 1.15;
          margin: 0 0 11px;
          @media #{$medium-up} {
            font-weight: 600;
          }
        }
      }
    }
  }
}
