.product-foundation-vto {
  &__mobile-ctrl {
    .container {
      .product-full {
        &__add-button {
          padding: 0 10px;
          font-size: 10px;
        }
      }
      .product-sub-heading {
        font-size: 13px;
      }
    }
  }
  &__container {
    ul.shade-list-vto {
      margin-bottom: 10px;
      li {
        .vto-filter-text {
          @include transform(translateX(-50%));
          #{$ldirection}: 50%;
          font-size: 11px;
          position: absolute;
        }
        &.vto-filter {
          &.first-filter {
            margin-#{$ldirection}: 10px;
          }
        }
      }
    }
  }
}
