.page-wrapper {
  .checkout {
    section.viewcart-buttons-panel {
      .content {
        a.continue {
          &-shopping {
            display: block !important;
            text-decoration: none;
          }
          &-checkout.disabled {
            background-color: $color-light-gray;
            border: 1px solid $color-light-gray;
          }
        }
      }
    }
    .security-info {
      img {
        margin-left: 190px;
      }
    }
    .payment-option {
      label {
        margin-top: 10px;
      }
    }
    .sample {
      .remove_link {
        display: none;
      }
    }
    .order-summary__shipping-method,
    .order-summary__shipping {
      display: block;
    }
    .top-viewcart-buttons {
      .viewcart-buttons-panel {
        .go-shopping {
          display: none;
        }
      }
    }
    .column.right {
      &.checkout__sidebar {
        width: 100%;
      }
    }
    .checkout__content {
      .shipping-panel {
        a.btn-mini {
          top: 22px;
        }
      }
      .payment-panel {
        .payment-edit-content {
          h3.checkout__subtitle {
            margin: 10px 0;
          }
          .field-are-required {
            margin-top: 40px;
          }
          .security-info {
            right: 8px;
            top: -50px;
            a {
              float: left;
              margin: -31px 8px 0 0;
            }
            p {
              margin: 0;
              float: right;
            }
          }
          .form-container {
            .payment-type {
              overflow: inherit;
              .related-media {
                img {
                  width: 37px;
                  height: 23px;
                }
              }
            }
          }
        }
      }
    }
    .checkout__sidebar {
      .checkout__header-item-count {
        margin-left: 10px;
      }
      a.btn-mini {
        top: 15px !important;
        right: -10px;
      }
      .checkout__panel-content {
        .cart-item {
          .cart-item__desc {
            .cart-item__desc-info {
              width: 60%;
            }
          }
        }
      }
      .order-summary__content {
        .float_mobile {
          display: block !important;
          .subtotal {
            &.label {
              margin: 13px 4px 0 -22px;
            }
            &.value {
              width: 26%;
            }
          }
          .shipping {
            &.label {
              margin: 13px -7px 0 -14px;
            }
            &.value {
              width: 26%;
            }
          }
        }
      }
    }
    .order-summary-panel {
      .order-summary__content {
        .label {
          width: 70%;
          float: left;
          text-align: left;
          line-height: 1.5;
          margin: 0;
          margin-bottom: 6px;
        }
        .value {
          float: right;
          margin: 0;
          width: 30%;
          line-height: 1.5;
          text-align: right;
          margin-bottom: 6px;
        }
      }
    }
  }
  #confirmation-page {
    .left {
      .confirmation-panel {
        .confirmation-panel__print-buttons {
          .print-confirm {
            line-height: 1.3;
            padding: 10px 30px;
            font-size: 11px;
          }
          .btn.button {
            width: 100%;
          }
        }
      }
    }
  }
  &.enhanced-cart-page {
    .enhanced_mobile_hidden {
      display: none !important;
    }
  }
}
.shipping-edit-address-content__content,
.payment-container {
  .address-form {
    .ship-type {
      display: block;
    }
  }
}
.invoice_container {
  position: relative;
  padding: 20px 20px 0 10px;
  margin-top: 20px;
  .menu-item-blocks {
    display: none;
  }
  .messages {
    background-color: $color-white;
    color: $color-light-maroon;
    border: 0;
  }
  .form-container {
    .form-item {
      margin: 0.5em;
      display: inline-block;
      width: 100%;
    }
    label {
      display: block;
    }
    input {
      width: 100%;
    }
  }
  .continue-button-wrapper {
    .submit {
      input {
        margin: 0.5em;
        width: 100%;
      }
    }
  }
}
.reciept-section {
  .order-details-page-reciept {
    .order_recipt {
      .order-summary {
        padding-left: 0;
      }
      td {
        width: 33%;
        word-break: break-word;
      }
    }
    footer {
      margin-top: 150px;
    }
  }
}
.order-details {
  .account-page {
    td {
      width: 33%;
      word-break: break-word;
    }
  }
}
.registration-panel {
  .btn.edit {
    display: none !important;
  }
  &.display .btn.edit {
    display: block !important;
  }
}

.checkout-progress-bar {
  margin: 18px 0;
  overflow: hidden;

  &__list {
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
    @media only screen and (min-width: 769px) and (max-width: 1024px) {
      width: 124%;
      margin-left: -12%;
    }
  }

  &__list-item {
    position: relative;
    float: left;
    width: 33.33%;
    color: $color-light-gray;
    font-size: 14px;
    text-align: center;

    a {
      color: $color-light-gray;
    }

    &:before {
      @include border-radius(14px);
      display: block;
      margin: 0 auto;
      margin-bottom: 6px;
      width: 14px;
      height: 14px;
      background: $color-light-gray;
      border: 1px solid $color-light-gray;
      color: $color-light-gray;
      content: '';
      line-height: 1.1;
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        @include border-radius(30px);
        width: 30px;
        height: 30px;
        border-width: 2px;
      }
    }

    &:after {
      position: absolute;
      top: 6px;
      left: -50%;
      width: 100%;
      height: 2px;
      background: $color-light-gray;
      content: '';
      z-index: -1;
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        top: 14px;
      }
    }

    &:first-child:after {
      content: none;
    }
  }
}

.active-panel-shipping,
.active-panel-registration {
  .checkout-progress-bar {
    .checkout-progress-bar__list-item--shipping {
      font-size: 18px;
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 24px;
      }
      &:before {
        background: $color-navy;
      }
      a {
        color: $color-navy;
      }
    }
  }
}
.active-panel-payment {
  .checkout-progress-bar {
    .checkout-progress-bar__list-item--payment {
      font-size: 18px;
      @media only screen and (min-width: 769px) and (max-width: 1024px) {
        font-size: 24px;
      }
      a {
        color: $color-navy;
      }
      &:before {
        background: $color-navy;
      }
    }
    .checkout-progress-bar__list-item--shipping {
      &:before {
        background: $color-navy;
      }
    }
  }
}
.active-panel-signin {
  .checkout-progress-bar {
    display: none;
  }
}

//Sticky Checkout
.page-wrapper-checkout {
  padding-bottom: 100px;
  .page-footer {
    display: none;
  }
  .checkout-footer {
    display: none;
  }
  .checkout {
    overflow: visible;
    .viewcart-buttons {
      display: none;
    }
    .sticky-checkout-button {
      border-top: 1px solid $color-black;
      background: $color-white;
      z-index: 1002;
      height: auto;
      padding: 10px 15px;
      @media #{$large-up} {
        display: none;
      }
      a {
        height: 50px;
        line-height: 4.1;
        vertical-align: middle;
        margin: 10px 0;
        padding: 0;
        border: 0;
      }
    }
    .hide-sticky-checkout-button {
      display: none;
    }
    .continue-button-wrapper {
      @include box-shadow(0 -3px 6px $color-light-gray);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      text-align: center;

      a.btn,
      input.btn {
        display: block;
        width: 100%;
        background-color: $color-navy;
        color: $color-white;
        font-weight: normal;

        @media #{$large-up} {
          font-weight: bold;
        }
      }

      ///
      /// Undo Sticky on Desktop
      ///
      @media #{$large-up} {
        position: static;
        box-shadow: none;
        width: 50%;
        height: auto;
        float: right;
        padding: $checkout-spacing--large $checkout-spacing--small $checkout-spacing--large 0;
      }
    }
  }
}
