/* gnav changes */
#cboxLoadedContent {
  .social-login.gnav {
    text-align: center;
    margin-#{$rdirection}: 0;
    @media #{$small-only} {
      margin-#{$rdirection}: 0;
    }
  }
  .social-login {
    &__container {
      text-align: center;
      @media #{$small-only} {
        text-align: center;
      }
    }
    &__divider {
      width: 100%;
      @media #{$small-only} {
        width: 86%;
      }
    }
    &__email-opt-in {
      text-align: left;
      margin-top: 15px;
      @media #{$small-only} {
        margin-top: 15px;
        padding-#{$ldirection}: 25px;
        text-align: left;
      }
      label {
        text-transform: none;
        letter-spacing: 0.8px;
        @media #{$small-only} {
          text-transform: none;
          letter-spacing: 0.8px;
        }
      }
    }
    &__terms {
      text-align: left;
      font-size: 12px;
      @media #{$small-only} {
        text-align: left;
        font-size: 12px;
        padding-#{$ldirection}: 25px;
      }
    }
  }
}

/* account signin changes */
.sign-in-page {
  .social-login {
    @media #{$medium-only} {
      margin-#{$rdirection}: 30px;
    }
    &__divider {
      width: 100%;
      @media #{$medium-only} {
        width: 85%;
      }
      @media #{$small-only} {
        width: 86%;
      }
    }
    &__email-opt-in {
      text-align: left;
      margin-top: 15px;
      @media #{$medium-only} {
        @include swap_direction(padding, 0 20px 0 35px);
      }
      @media #{$small-only} {
        @include swap_direction(padding, 0 17px 0 25px);
      }
      label {
        text-transform: none;
        letter-spacing: 0.8px;
      }
    }
    &__terms {
      text-align: left;
      padding: 0;
      @media #{$medium-only} {
        padding-#{$ldirection}: 35px;
      }
      @media #{$small-only} {
        padding-#{$ldirection}: 25px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}

/* account signin connected text change */
.sidebar-page__sidebar {
  .account-profile__connected-accounts {
    text-transform: uppercase;
    font-size: 12px;
  }
}

/* my account registration page */
.registration-page {
  .sidebar-page__content {
    padding-bottom: 50px;
  }
  .social-info {
    h3 {
      font-size: 20px;
      font-family: $optimaregular;
      @media #{$medium-up} {
        font-size: 30px;
        font-family: $optimaregular;
        border-bottom: solid 1px $color-light-gray;
        padding-bottom: 16px;
      }
    }
    @media #{$medium-up} {
      margin-#{$ldirection}: 30%;
    }
    @media #{$large-up} {
      margin-#{$ldirection}: 25%;
    }
    @media #{$xxlarge-up} {
      margin-#{$ldirection}: 19%;
    }
    @include swap_direction(padding, 20px 20px 0 20px);
    &__content {
      p {
        font-size: 12px;
        a {
          border-bottom: solid 1px $color-light-gray;
        }
      }
    }
  }
}

/* checkout index page button */
.checkout__content {
  .social-login {
    &__divider {
      width: 100%;
      margin-top: 15px;
    }
    &__email-opt-in {
      margin-top: 15px;
      text-align: left;
    }
    &__opt-in-label {
      text-transform: none;
      letter-spacing: 0.8px;
      font-size: 15px;
    }
    &__terms {
      @include swap_direction(padding, 0);
      text-align: left;
    }
  }
}

/* checkout confirmation button */
.checkout-confirmation-page {
  .social-login {
    @include swap_direction(padding, 0);
    &__email-opt-in {
      label {
        font-size: 15px;
      }
    }
  }
}
#confirm {
  .social-login {
    padding: 0;
  }
}
